<template>
  <div>
    <v-autocomplete
      :value="value"
      placeholder="Search.. ."
      :loading="loadingUnit"
      :items="unitOptions"
      :search-input.sync="searchUnit"
      hide-details="auto"
      label="Unit"
      item-text="name"
      item-value="name"
      outlined
      dense
      hide-no-data
      @keyup.enter="fetchUnits"
      @change="updateValue"
    >
      <template v-slot:append-outer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-btn
            color="primary"
            x-small
            fab
            @click="addNew"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-slide-x-reverse-transition>
      </template>
    </v-autocomplete>
    <unit-editor-dialog
      ref="unitEditorDialog"
      @saved="saved"
    ></unit-editor-dialog>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import useUnits from '../useUnits'
import UnitEditorDialog from './UnitEditorDialog.vue'

export default {
  components: { UnitEditorDialog },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    value: null,
    initial: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const unitEditorDialog = ref(null)
    const {
      units: unitOptions, searchUnit, getUnits, loading: loadingUnit,
    } = useUnits()

    const fetchUnits = () => {
      getUnits({ search: searchUnit.value })
    }

    const updateValue = val => {
      emit('setUnit', val, props.index)
    }

    onMounted(() => {
      if (props.initial.length > 0 && props.value !== null) {
        unitOptions.value = props.initial
      }
    })

    const addNew = () => {
      unitEditorDialog.value.isDialogVisible = true
    }

    const saved = data => {
      unitOptions.value.push(data)
      emit('setUnit', data.name, props.index)
    }

    return {
      addNew,
      unitEditorDialog,
      saved,

      unitOptions,
      getUnits,
      loadingUnit,
      fetchUnits,
      searchUnit,

      updateValue,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
