<template>
  <v-autocomplete
    :value="value"
    placeholder="Search.. ."
    :loading="loadingEmployee"
    :items="employeeOptions"
    :search-input.sync="searchEmployee"
    hide-details="auto"
    :label="label"
    item-text="full_name"
    item-value="id"
    outlined
    dense
    hide-no-data
    return-object
    @keyup.enter="fetchEmployees"
    @change="updateValue"
  >
  </v-autocomplete>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'
import useEmployees from './useEmployees'

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: 'Employee',
    },
    value: null,
    initial: {
      type: Array,
      default: () => [],
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      employees: employeeOptions, searchEmployee, getEmployees, loading: loadingEmployee,
    } = useEmployees()

    const fetchEmployees = () => {
      getEmployees({ searchQuery: searchEmployee.value, roles: props.roles })
    }

    const updateValue = val => {
      emit('setEmployee', val)
    }

    onMounted(() => {
      if (props.initial.length > 0 && props.value !== null) {
        employeeOptions.value = props.initial.filter(o => o.id > 0 && o.id !== null)
      }

      fetchEmployees()
    })

    const addNew = () => {
      //
    }

    return {
      employeeOptions,
      getEmployees,
      loadingEmployee,
      fetchEmployees,
      searchEmployee,

      updateValue,

      addNew,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
