<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Item</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-textarea
                  v-model="form.description"
                  :error-messages="errors.description"
                  label="Description"
                  outlined
                  dense
                  hide-details
                  rows="3"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          outlined
          @click="isDialogVisible = false"
        >
          Close
        </v-btn>
        <v-btn
          color="success"
          :disabled="saving"
          @click="create"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from '@axios'

const formInit = () => ({
  description: null,
})

export default {
  setup(props, { emit }) {
    const saving = ref(false)
    const isDialogVisible = ref(false)

    const form = ref(formInit())
    const errors = ref([])
    const create = () => {
      saving.value = true
      axios
        .post('/items', form.value)
        .then(r => {
          saving.value = false
          isDialogVisible.value = false
          emit('saved', r.data)
        })
        .catch(error => {
          if (error.response.status === 422) {
            errors.value = error.response.data.errors
          }
          saving.value = false
        })
    }

    return {
      form,
      errors,
      saving,

      isDialogVisible,

      create,
    }
  },
}
</script>
