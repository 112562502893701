<template>
  <div>
    <v-autocomplete
      :value="value"
      placeholder="Search.. ."
      :loading="loadingItem"
      :items="itemOptions"
      :search-input.sync="searchItem"
      hide-details="auto"
      label="Item"
      item-text="description"
      item-value="id"
      outlined
      dense
      hide-no-data
      return-object
      :error-messages="error"
      @keyup.enter="fetchItems"
      @change="updateValue"
    >
      <template v-slot:append-outer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-btn
            color="primary"
            x-small
            fab
            @click="addNew"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-slide-x-reverse-transition>
      </template>
    </v-autocomplete>
    <item-editor-dialog
      ref="itemEditorDialog"
      @saved="saved"
    ></item-editor-dialog>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import useItems from '../useItems'
import ItemEditorDialog from './ItemEditorDialog.vue'

export default {
  components: { ItemEditorDialog },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    error: null,
    index: {
      type: Number,
      required: true,
    },
    value: null,
    initial: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const itemEditorDialog = ref(null)
    const {
      items: itemOptions, searchItem, getItems, loading: loadingItem,
    } = useItems()

    const fetchItems = () => {
      getItems({ search: searchItem.value })
    }

    const updateValue = val => {
      emit('setItem', val, props.index)
    }

    onMounted(() => {
      if (props.initial.length > 0 && props.value !== null) {
        itemOptions.value = props.initial.filter(o => o.id > 0 && o.id !== null)
      }
    })

    const addNew = () => {
      itemEditorDialog.value.isDialogVisible = true
    }

    const saved = data => {
      itemOptions.value.push(data)
      emit('setItem', data, props.index)
    }

    return {
      itemEditorDialog,
      saved,

      itemOptions,
      getItems,
      loadingItem,
      fetchItems,
      searchItem,

      updateValue,

      addNew,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
