<template>
  <v-card>
    <v-card-title>Request for Quotation</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mr-2"
        :disabled="saving"
        @click="saveRfq"
      >
        Save
      </v-btn>
      <v-btn
        v-if="$router.currentRoute.params.id"
        color="primary"
        class="mr-2"
        outlined
        :disabled="generatingPdf"
        @click="generatePdf"
      >
        <v-icon small>
          {{ icons.mdiExportVariant }}
        </v-icon>
        <span class="pl-2">Export PDF</span>
      </v-btn>
      <v-btn
        v-if="$router.currentRoute.params.id"
        color="primary"
        class="mr-2"
        outlined
        :disabled="generatingPdf"
        @click="genateBidForm"
      >
        <v-icon small>
          {{ icons.mdiExportVariant }}
        </v-icon>
        <span class="pl-2">Download Bid Form</span>
      </v-btn>
    </v-card-actions>
    <v-card-text>
      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="form.pr_number"
              :error-messages="errors.pr_number"
              label="PR No"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="form.quotation_number"
              label="Quotation No"
              outlined
              dense
              hide-details
              placeholder="Auto generated"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-menu
              v-model="quotationDatePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              :error-messages="errors.quotation_date"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="form.quotation_date"
                  readonly
                  label="Date"
                  v-bind="attrs"
                  outlined
                  dense
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="form.quotation_date"
                color="primary"
                @input="quotationDatePicker = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="form.abc"
              :error-messages="errors.abc"
              label="ABC"
              outlined
              dense
              hide-details
              type="number"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <office-select
              v-model="form.office_name"
              :error="errors.office_name"
              :initial="initialOffices"
              @setOffice="setOffice"
            ></office-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <employee-select
              v-model="form.requested_by"
              label="Requested By"
              :initial="[]"
              :roles="['Department Head']"
              @setEmployee="setEmployeeRequestedBy"
            ></employee-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <employee-select
              v-model="form.canvasser_id"
              label="Canvasser"
              :initial="[]"
              :roles="['Canvassing Personnel']"
              @setEmployee="setEmployeeCanvasser"
            ></employee-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <employee-select
              v-model="form.supply_officer_id"
              label="Supply Officer"
              :initial="[]"
              :roles="['Supply Officer']"
              @setEmployee="setEmployeeSupplyOfficer"
            ></employee-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-textarea
              v-model="form.purpose"
              label="Purpose"
              rows="2"
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-tabs
      v-model="rfqTab"
      fixed-tabs
    >
      <v-tab>
        <h4>Items ({{ form.items.length }})</h4>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="rfqTab">
      <v-tab-item>
        <v-card>
          <v-card-text>
            <div class="pb-10">
              <div
                v-for="(item, itemIndex) in form.items"
                :key="itemIndex"
                class="single-service-form"
                style="margin-top: 1rem"
              >
                <div class="add-services-header mb-2 d-none d-md-flex">
                  <div class="px-5 flex-grow-1 font-weight-semibold">
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span>Qty</span>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <span>Item</span>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span>Model/Brand Offered</span>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span>Unit Cost</span>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <span>Unit</span>
                      </v-col>
                    </v-row>
                  </div>
                  <div class="header-spacer"></div>
                </div>
                <v-card
                  outlined
                  class="d-flex"
                >
                  <div class="pa-5 flex-grow-1">
                    <v-row>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="item.quantity"
                          outlined
                          dense
                          min="0"
                          type="number"
                          hide-details="auto"
                          placeholder="$"
                          clearable
                          @input="
                            val => {
                              item.quantity = Math.abs(val)
                            }
                          "
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <item-select
                          v-model="item.item_id"
                          :index="itemIndex"
                          :initial="initialItems"
                          @setItem="setItem"
                        ></item-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="item.brand"
                          label=""
                          outlined
                          dense
                          clearable
                          hide-details="auto"
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <v-text-field
                          v-model="item.unit_cost"
                          outlined
                          dense
                          min="0"
                          type="number"
                          hide-details="auto"
                          placeholder="$"
                          clearable
                          @input="
                            val => {
                              item.unit_cost = Math.abs(val)
                            }
                          "
                        ></v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="2"
                      >
                        <unit-select
                          v-model="item.unit"
                          :index="itemIndex"
                          :initial="initialUnits"
                          @setUnit="setUnit"
                        ></unit-select>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-textarea
                          v-model="item.specification"
                          rows="2"
                          outlined
                          dense
                          label="Specification"
                          clearable
                          auto-grow
                          hide-details="auto"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- Service Actions -->
                  <div class="d-flex flex-column item-actions rounded-0 pa-1">
                    <v-btn
                      icon
                      small
                      @click="removeItem(item, itemIndex)"
                    >
                      <v-icon size="20">
                        {{ icons.mdiClose }}
                      </v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                  </div>
                </v-card>
              </div>
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  offset="5"
                >
                  <v-btn
                    color="primary"
                    class="mt-4 col-4"
                    outlined
                    block
                    @click="addNewItem"
                  >
                    Add Item
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <snackbar
      ref="snackbar"
      color="success"
      :message="message"
    ></snackbar>
  </v-card>
</template>

<script>
import axios from '@axios'
import {
  mdiCalendarCheckOutline, mdiClose, mdiCogOutline, mdiPlus, mdiSpeedometer,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import router from '@/router'
import useRfq from './useRfq'

import EmployeeSelect from '../employees/EmployeeSelect.vue'
import OfficeSelect from '../OfficeSelect.vue'
import Snackbar from '../Snackbar.vue'
import ItemSelect from './ItemSelect.vue'
import UnitSelect from './UnitSelect.vue'

const today = new Date()
const formInit = () => ({
  pr_number: null,
  quotation_number: null,
  quotation_date: today.toISOString().substr(0, 10),
  office_name: null,
  abc: null,
  items: [],
  deleted: [],
  requested_by: null,
  canvasser_id: null,
  supply_officer_id: null,
  purpose: null,
})
export default {
  components: {
    ItemSelect,
    UnitSelect,
    Snackbar,
    EmployeeSelect,
    OfficeSelect,
  },
  setup() {
    const saving = ref(false)
    const generatingPdf = ref(false)
    const snackbar = ref(null)
    const message = ref('')
    const rfqTab = ref(null)
    const quotationDatePicker = ref(false)
    const form = ref(formInit())
    const errors = ref([])
    const initialItems = ref([])
    const initialUnits = ref(['Piece'])
    const initialOffices = ref([])

    const { rfq, getRfq } = useRfq()
    const resetForm = () => {
      form.value = formInit()
    }

    const setEmployeeRequestedBy = val => {
      form.value.requested_by = val?.id
    }

    const setEmployeeCanvasser = val => {
      form.value.canvasser_id = val?.id
    }

    const setEmployeeSupplyOfficer = val => {
      form.value.supply_officer_id = val?.id
    }

    const generatePdf = () => {
      generatingPdf.value = true
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-rfq/${router.currentRoute.params.id}`
      generatingPdf.value = false
    }

    const genateBidForm = () => {
      generatingPdf.value = true
      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-bid-form/${router.currentRoute.params.id}`
      generatingPdf.value = false
    }

    const saveRfq = () => {
      saving.value = true
      if (form.value.id) {
        axios
          .put(`/rfqs/${form.value.id}`, form.value)
          .then(() => {
            saving.value = false
            message.value = 'RFQ saved.'
            snackbar.value.visible = true
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      } else {
        axios
          .post('/rfqs', form.value)
          .then(() => {
            saving.value = false
            message.value = 'RFQ saved.'
            snackbar.value.visible = true
            router.push({ name: 'rfq' })
          })
          .catch(error => {
            if (error.response.status === 422) {
              errors.value = error.response.data.errors
            }
            saving.value = false
          })
      }
    }

    const removeItem = (item, index) => {
      if (item.id) {
        form.value.deleted.push(item.id)
      }
      form.value.items.splice(index, 1)
    }
    const addNewItem = () => {
      // check if Piece unit exists
      if (!initialUnits.value.includes('Piece')) {
        initialUnits.value.push('Piece')
      }
      form.value.items.push({
        id: null,
        item_id: null,
        unit: 'Piece',
        unit_cost: 0,
        quantity: 1,
        description: null,
        specification: null,
        brand: null,
      })
    }

    const setItem = (item, index) => {
      form.value.items[index].item_id = item?.id
      form.value.items[index].description = item?.description
    }

    const setUnit = (unit, index) => {
      form.value.items[index].unit = unit
    }

    const setOffice = office => {
      form.value.office_name = office
    }

    if (router.currentRoute.params.id) {
      // fetch rfq from the server
      saving.value = true
      getRfq(router.currentRoute.params.id).then(response => {
        const { data } = response
        form.value = data
        form.value.deleted = []

        initialItems.value = data.items.map(item => {
          const container = item.item

          return container
        })

        initialOffices.value = [data.office_name]

        initialUnits.value = data.items.map(item => {
          const container = item.unit

          return container
        })

        saving.value = false
      })
    }

    return {
      saving,
      generatingPdf,
      message,
      snackbar,
      quotationDatePicker,
      rfqTab,
      form,
      errors,

      rfq,
      getRfq,

      resetForm,
      setEmployeeRequestedBy,
      setEmployeeCanvasser,
      setEmployeeSupplyOfficer,
      saveRfq,
      generatePdf,
      genateBidForm,
      addNewItem,
      removeItem,

      setItem,

      initialItems,

      initialUnits,

      initialOffices,

      setUnit,
      setOffice,

      // icons
      icons: {
        mdiPlus,
        mdiCalendarCheckOutline,
        mdiSpeedometer,
        mdiCogOutline,
        mdiClose,
      },
    }
  },
}
</script>
